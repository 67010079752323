import React from 'react';

const Dice = ({ result }) => {
    return (
        <div className="diceArea">
            <div className="cube">
                <div className="cube-face">
                    <span className="result">{result}</span>
                </div>
            </div>
        </div>
    );
};

export default Dice;
