import './App.scss';
import CharacterSheet from "./components/CharacterSheet";
import DiceRoll from "./components/DiceRoll";
import MonsterDeck from "./components/MonsterDeck";
import DungeonCreator from "./components/DungeonCreator";
import {useState} from "react";
import dice from './images/dice.png';
import viking from './images/viking.png';
import orc from './images/orc.png';
import pencil from './images/pencil.png';
import map from './images/map.svg';

function App() {
  const [tab, setTab] = useState('home');

  return (
      <div className="App">
        <div className="tabs">
            <img src={dice} alt="" onClick={() => setTab('home')}/>
            <img src={viking} alt="" onClick={() => setTab('characterSheet')}/>
            <img src={pencil} alt="" onClick={() => setTab('characterSheetForm')}/>
            <img src={orc} alt="" onClick={() => setTab('monsters')}/>
            <img src={map} alt="" onClick={() => setTab('dungeonCreator')}/>
        </div>
        {tab==="home" && <DiceRoll />}
        {(tab==="characterSheet" || tab==="characterSheetForm") && <CharacterSheet tab={tab}/>}
        {tab==="monsters" && <MonsterDeck/>}
        {tab==="dungeonCreator" && <DungeonCreator/>}
      </div>
  )
}

export default App
