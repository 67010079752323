import React from 'react';

const Characters = ({ characters }) => {
    return (
        <div className="infoCharSheet">
            {characters.length > 0 && characters.map((character, index) => (
                <div className="infoCharacter" key={index}>
                    <section className="charname">
                        <h3>Nom du personnage</h3>
                        <div className="infoChar">{character.charname}</div>
                    </section>
                    <section className="misc">
                        <div className="miscElement">
                            <h3>Classe</h3>
                            <div className="infoChar">{character.class}</div>
                        </div>
                        <div className={`miscElement ${character.customClass ? "" : "hidden"}`}>
                            <h3>Laisse moi dont en inventer une!</h3>
                            <div className="infoChar">{character.customClass}</div>
                        </div>
                        <div className="miscElement">
                            <h3>Niveau</h3>
                            <div className="infoChar">{character.level}</div>
                        </div>
                        <div className="miscElement">
                            <h3>Nom du joueur</h3>
                            <div className="infoChar">{character.playername}</div>
                        </div>
                        <div className="miscElement">
                            <h3>Race</h3>
                            <div className="infoChar">{character.race}</div>
                        </div>
                        <div className={`miscElement ${character.customRace ? "" : "hidden"}`}>
                            <h3>Laisse moi dont en inventer une!</h3>
                            <div className="infoChar">{character.customRace}</div>
                        </div>
                        <div className={`miscElement ${character.subrace ? "" : "hidden"}`}>
                            <h3>Sous-Race (pour Will hahaha)</h3>
                            <div className="infoChar">{character.subrace}</div>
                        </div>
                        <div className="miscElement">
                            <h3>Alignement</h3>
                            <div className="infoChar">{character.alignment}</div>
                        </div>
                        <div className="background_story">
                            <h3>Histoire du personnage</h3>
                            <div className="infoChar">{character.background_story}</div>
                        </div>
                    </section>
                    <section className="attributes">
                        <div className="scores">
                            <h2>Attributs</h2>
                            <div className="attribute">
                                <div className="score">
                                    <h3>Force</h3>
                                    <div className="infoChar">{character.Strengthscore}</div>
                                </div>
                                <div className="modifier">
                                    <span>Modificateur {Math.floor((parseInt(character.Strengthscore) - 10) / 2)}</span>
                                </div>
                            </div>
                            <div className="attribute">
                                <div className="score">
                                    <h3>Dextérité</h3>
                                    <div className="infoChar">{character.Dexterityscore}</div>
                                </div>
                                <div className="modifier">
                                    <span>Modificateur {Math.floor((parseInt(character.Dexterityscore) - 10) / 2)}</span>
                                </div>
                            </div>
                            <div className="attribute">
                                <div className="score">
                                    <h3>Constitution</h3>
                                    <div className="infoChar">{character.Constitutionscore}</div>
                                </div>
                                <div className="modifier">
                                    <span>Modificateur {Math.floor((parseInt(character.Constitutionscore) - 10) / 2)}</span>
                                </div>
                            </div>
                            <div className="attribute">
                                <div className="score">
                                    <h3>Sagesse</h3>
                                    <div className="infoChar">{character.Wisdomscore}</div>
                                </div>
                                <div className="modifier">
                                    <span>Modificateur {Math.floor((parseInt(character.Wisdomscore) - 10) / 2)}</span>
                                </div>
                            </div>
                            <div className="attribute">
                                <div className="score">
                                    <h3>Intelligence</h3>
                                    <div className="infoChar">{character.Intelligencescore}</div>
                                </div>
                                <div className="modifier">
                                    <span>Modificateur {Math.floor((parseInt(character.Intelligencescore) - 10) / 2)}</span>
                                </div>
                            </div>
                            <div className="attribute">
                                <div className="score">
                                    <h3>Charisme</h3>
                                    <div className="infoChar">{character.Charismascore}</div>
                                </div>
                                <div className="modifier">
                                    <span>Modificateur {Math.floor((parseInt(character.Charismascore) - 10) / 2)}</span>
                                </div>
                            </div>
                        </div>

                        <div className="attr-applications">
                            <div className="proficiencybonus">
                                <h2>Bonus de Maîtrise</h2>
                                <h3>+{Math.floor((parseInt(character.level) - 1) / 4) + 2}</h3>
                            </div>
                            <div className="saves">
                                <h2>Jets de sauvegarde</h2>
                                <div className="save">
                                    <h3>Force:</h3>
                                    <div className="infoChar">{Math.floor((parseInt(character.Strengthscore) - 10) / 2)}</div>
                                </div>
                                <div className="save">
                                    <h3>Dextérité:</h3>
                                    <div className="infoChar">{Math.floor((parseInt(character.Dexterityscore) - 10) / 2)}</div>
                                </div>
                                <div className="save">
                                    <h3>Constitution:</h3>
                                    <div className="infoChar">{Math.floor((parseInt(character.Constitutionscore) - 10) / 2)}</div>
                                </div>
                                <div className="save">
                                    <h3>Sagesse:</h3>
                                    <div className="infoChar">{Math.floor((parseInt(character.Wisdomscore) - 10) / 2)}</div>
                                </div>
                                <div className="save">
                                    <h3>Intelligence:</h3>
                                    <div className="infoChar">{Math.floor((parseInt(character.Intelligencescore) - 10) / 2)}</div>
                                </div>
                                <div className="save">
                                    <h3>Charisme:</h3>
                                    <div className="infoChar">{Math.floor((parseInt(character.Charismascore) - 10) / 2)}</div>
                                </div>
                            </div>
                            <div className="skills">
                                <h2>Compétences</h2>
                                <div className="skill">
                                    <h3 className={character.selectAcrobatics? "Selected":""}>Acrobaties (DEX)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Dexterityscore) - 10) / 2) + (character.selectAcrobatics ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectAnimalHandling? "Selected":""}>Animal Handling - Dressage (SAG)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectAnimalHandling ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectArcana ? "Selected" : ""}>Arcana - Arcanes (INT)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectArcana ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectAthletics ? "Selected" : ""}>Athletics - Athlétisme (FOR)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Strengthscore) - 10) / 2) + (character.selectAthletics ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectDeception? "Selected":""}>Deception - Tromperie (CHA)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectDeception ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectHistory ? "Selected" : ""}>History - Histoire (INT)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectHistory ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectInsight? "Selected":""}>Insight - Perspicacité (SAG)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectInsight ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectIntimidation? "Selected":""}>Intimidation - Intimidation (CHA)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectIntimidation ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectInvestigation? "Selected":""}>Investigation - Investigation (INT)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectInvestigation ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectMedicine? "Selected":""}>Medicine (SAG)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectMedicine ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectNature? "Selected":""}>Nature (INT)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectNature ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectPerception? "Selected":""}>Perception (SAG)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectPerception ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectPerformance? "Selected":""}>Performance - Représentation (CHA)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectPerformance ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectPersuasion? "Selected":""}>Persuasion (CHA)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectPersuasion ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectReligion? "Selected":""}>Religion (INT)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectReligion ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectSleightofHand? "Selected":""}>Sleight of Hand - Discrétion (DEX)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Dexterityscore) - 10) / 2) + (character.selectSleightofHand ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectStealth ? "Selected" : ""}>Stealth - Furtivité (DEX)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Dexterityscore) - 10) / 2) + (character.selectStealth ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                                <div className="skill">
                                    <h3 className={character.selectSurvival? "Selected":""}>Survival - Survie (SAG)</h3>
                                    <div className="infoChar">
                                        {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectSurvival ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="passive-perception">
                        <h2>Perception Passive (SAG)</h2>
                        <div className="infoChar">
                            {10 + Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectPerception ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                        </div>
                    </div>
                    <div className={`otherprofs ${character.proficiencies ? "" : "hidden"}`}>
                        <h2>Compétences ou Maîtrises Supplémentaires</h2>
                        <div className="infoChar">{character.proficiencies}</div>
                    </div>
                    <div className={`otherprofs ${character.languages ? "" : "hidden"}`}>
                        <h2>Languages</h2>
                        <div className="infoChar">{character.languages}</div>
                    </div>
                    <section className="combat">
                        <h2>Combat stats</h2>
                        <div className="armorclass">
                            <h3>Classe d'armure</h3>
                            <div className="infoChar">{character.ac}</div>
                        </div>
                        <div className="initiative">
                            <h3>Modificateur d'initiative</h3>
                            <div className="infoChar">{character.initiative}</div>
                        </div>
                        <div className="speed">
                            <h3>Speed</h3>
                            <div className="infoChar">{character.speed}</div>
                        </div>
                        <div className="hp">
                            <h3>Les points de vie</h3>
                            <div className="max">
                                <h4>Points de Vie Maximum</h4>
                                <div className="infoChar">{character.maxhp}</div>
                            </div>
                            <div className="current">
                                <h4>Points de vie actuellement</h4>
                                <div className="infoChar">{character.currenthp}</div>
                            </div>
                            <div className="temporary">
                                <h4>Points de vie temporaires</h4>
                                <div className="infoChar">{character.temphp}</div>
                            </div>
                        </div>
                        <div className="hitdice">
                            <h3>Dés de vie</h3>
                            <div className="total">
                                <h4>Total</h4>
                                <div className="infoChar">{character.totalhd}</div>
                            </div>
                            <div className="remaining">
                                <h4>Restant</h4>
                                <div className="infoChar">{character.remaininghd}</div>
                            </div>
                        </div>
                        <div className="deathsaves">
                            <h3>Jets de Sauvegarde contre la Mort</h3>
                            <div className="deathsuccesses">
                                <h4>Réussites</h4>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="deathfails">
                                <h4>Échecs</h4>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </section>
                    <section className="attacksandspellcasting">
                        <h2>Attaques & Sorts</h2>
                            <table>
                                <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Bonus d'attaque</th>
                                    <th>Dégât et type</th>
                                </tr>
                                </thead>
                                <tbody>
                                {character.attacks.map((atk, index) => (
                                    <tr key={index}>
                                        <td><div className="infoChar">{atk.name}</div></td>
                                        <td><div className="infoChar">{atk.bonus}</div></td>
                                        <td><div className="infoChar">{atk.dmg}</div></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                    </section>
                    <section className="equipmentMoney">
                        <h2>Equipment</h2>
                        <div className="money">
                            <div className="cash">Cuivre</div>
                            <div className="infoChar">{character.cp}</div>
                            <div className="cash">Argent</div>
                            <div className="infoChar">{character.sp}</div>
                            <div className="cash">Or</div>
                            <div className="infoChar">{character.gp}</div>
                            <div className="cash">Platine</div>
                            <div className="infoChar">{character.pp}</div>
                        </div>
                        <div className="equipment">
                            <div>{character.equipment}</div>
                        </div>
                        <div className="poids">
                            <h3>Poids</h3>
                            <div>{character.poids}</div>
                        </div>
                    </section>
                    <section className="flavor">
                        <h2>Informations le fun sur le personnage</h2>
                        <div className="personality">
                            <h3>Personalité</h3>
                            <div className="infoChar">{character.personality}</div>
                        </div>
                        <div className="ideals">
                            <h3>Idéaux</h3>
                            <div className="infoChar">{character.ideals}</div>
                        </div>
                        <div className="bonds">
                            <h3>Liens</h3>
                            <div className="infoChar">{character.bonds}</div>
                        </div>
                        <div className="flaws">
                            <h3>Défauts</h3>
                            <div className="infoChar">{character.flaws}</div>
                        </div>
                    </section>
                    <section className="features">
                        <h2>Caractéristiques et Traits</h2>
                        <div className="infoChar">{character.features}</div>
                    </section>
                </div>
            ))}
        </div>
    );
};

export default Characters;
