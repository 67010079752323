import React, { useState } from 'react';
import cardBack from '../images/monsterCards/cardBack.png';

const importAll = (r) => {
    return r.keys().map((key) => ({
        path: r(key),
        name: key.split('/').pop().split('.')[0], // Nom du fichier
        letter: key.split('/').pop().charAt(0) // Première lettre du nom du fichier
    }));
};

const monsterImages = importAll(require.context('../images/monsterCards', true, /\.(png|jpe?g|svg)$/));

function MonsterDeck() {
    const [selectedMonster, setSelectedMonster] = useState(null);
    const [difficulty, setDifficulty] = useState('Apprenti'); // Initialisé à 'Facile'

    const probabilities = {
        'Apprenti': { 'D': 75, 'C': 25 },
        'Explorateur': { 'D': 20, 'C': 75, 'B': 5 },
        'Aventurier Aventureux': { 'C': 70, 'B': 30 },
        'Expert Naissant': { 'C': 20, 'B': 75, 'A': 5 },
        'Expert': { 'B': 70, 'A': 30 },
        'HAHAHA Bonne chance': { 'A': 80, 'S': 20 },
        'Mazo': { 'S': 100 }
    };

    const categorizedImages = {
        A: [], B: [], C: [], D: [], S: []
    };

    monsterImages.forEach((image) => {
        const prefix = image.name.charAt(0); // Obtient la première lettre du nom
        if (categorizedImages[prefix]) {
            categorizedImages[prefix].push(image);
        }
    });

    const handleDeckClick = () => {
        const selectedProbabilities = probabilities[difficulty];
        let total = 0;
        const randomNumber = Math.random() * 100;
        let selectedCategory = null;

        for (const [category, probability] of Object.entries(selectedProbabilities)) {
            total += probability;
            if (randomNumber <= total) {
                selectedCategory = category;
                break;
            }
        }

        if (selectedCategory) {
            const categoryImages = categorizedImages[selectedCategory];
            const randomIndex = Math.floor(Math.random() * categoryImages.length);
            setSelectedMonster(categoryImages[randomIndex]);
        }
    };

    return (
        <div className="monster-deck">
            <div onClick={handleDeckClick}>
                {selectedMonster ? (
                    <div className="monster-card">
                        <img src={selectedMonster.path} alt={selectedMonster.name}/>
                    </div>
                ) : (
                    <div className="card-back">
                        <img src={cardBack} alt="Card Back"/>
                    </div>
                )}
            </div>
            <select className="select-difficulty" value={difficulty} onChange={(e) => setDifficulty(e.target.value)}>
                <option value="Apprenti">Apprenti</option>
                <option value="Explorateur">Explorateur</option>
                <option value="Aventurier Aventureux">Aventurier Aventureux</option>
                <option value="Expert Naissant">Expert Naissant</option>
                <option value="Expert">Expert</option>
                <option value="HAHAHA Bonne chance">HAHAHA Bonne chance</option>
                <option value="Mazo">Mazo</option>
            </select>
        </div>
    );
}

export default MonsterDeck;
