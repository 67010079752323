import React, {useEffect, useState} from 'react';
const importAll = (r) => {
    return r.keys().map((key) => ({
        path: r(key),
        name: key.split('/').pop().split('.')[0], // Nom du fichier
        letter: key.split('/').pop().charAt(0) // Première lettre du nom du fichier
    }));
};

const tilesImages = importAll(require.context('../images/tiles', true, /\.(png|jpe?g|svg)$/));

const Tile = ({ index, tile, onExplore }) => {
    const { forme, coordinates, doors, monsters } = tile;
    const [monsterPositions, setMonsterPositions] = useState([]);

    const visited = tile.visited ? "visited" : "";

    let imgTile = "TILE";
    if (doors["north"]) {
        imgTile += "N";
    }
    if (doors["east"]) {
        imgTile += "E";
    }
    if (doors["south"]) {
        imgTile += "S";
    }
    if (doors["west"]) {
        imgTile += "W";
    }

    const tileImage = tilesImages.find(image => image.name === imgTile);

    const tileContainerStyle = {
        left: `${coordinates.x}px`,
        top: `${coordinates.y}px`
    }

    const tileStyle = {
        backgroundImage: `url(${tileImage.path})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '500px',
        height: '500px'
    }
    useEffect(() => {
        if(tile.index !== 0) {
            let positions = [];
            let tempmonsters = [...monsters];
            for (let i = 0; i < 5; i++) {
                for (let j = 0; j < 5; j++) {
                    let monster = null;
                    if (i > 0 && i < 4 && j > 0 && j < 4 && tempmonsters.length > 0) {

                        const randomMonsterIndex = Math.floor(Math.random() * tempmonsters.length);
                        monster = tempmonsters[randomMonsterIndex];
                        tempmonsters.splice(randomMonsterIndex, 1);
                        positions = [...positions, { i, j, monster }];
                    }
                }
            }
            setMonsterPositions(positions);
        }
    }, [tile.index, monsters]);

    const squares = [];
    if(index !== 0) {
        for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 5; j++) {
                const monster = monsterPositions.find(position => position.i === i && position.j === j);
                if (monster) {
                    squares.push(
                        <div className="square" style={{top: `${i*100+20}px`, left: `${j*100+20}px`}}>
                            <img className="dungeonMonster" src={monster.monster.path} alt={monster.monster.name} />
                        </div>
                    );
                }else{
                    squares.push(
                        <div className="square" style={{top: `${i*100+20}px`, left: `${j*100+20}px`}}>
                        </div>
                    );
                }
            }
        }
    }

    return (
        <div className={`tileContainer ${visited}`} style={tileContainerStyle}>
            <div className={`tile ${forme}`} style={tileStyle}>
                {squares}
                {doors["north"] && <button className="buttons-tile north" onClick={() => onExplore(index, 'north')}></button>}
                {doors["east"] && <button className="buttons-tile east" onClick={() => onExplore(index, 'east')}></button>}
                {doors["south"] && <button className="buttons-tile south" onClick={() => onExplore(index, 'south')}></button>}
                {doors["west"] && <button className="buttons-tile west" onClick={() => onExplore(index, 'west')}></button>}
            </div>
        </div>
    );
};

export default Tile;
