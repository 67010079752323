import React from 'react';

const CharacterForm = ({ character, handleChange, handleSubmit }) => {

    return (
        <form className="charsheet" onSubmit={handleSubmit}>
            <header>
                <section className="charname">
                    <label htmlFor="charname">Nom du personnage</label>
                    <input name="charname" value={character.charname} onChange={handleChange} />
                </section>
                <section className="misc">
                    <label htmlFor="class">Classe</label>
                    <select name="class" onChange={handleChange} value={character.class}>
                        <option value=""></option>
                        <option value="Barbarian">Barbare</option>
                        <option value="Bard">Bard</option>
                        <option value="Cleric">Clerc</option>
                        <option value="Druid">Druide</option>
                        <option value="Fighter">Combattant</option>
                        <option value="Monk">Moine</option>
                        <option value="Paladin">Paladin</option>
                        <option value="Ranger">Ranger</option>
                        <option value="Rogue">Rogue</option>
                        <option value="Sorcerer">Sorcier</option>
                        <option value="Warlock">Ensorceleur (Warlock)</option>
                        <option value="Wizard">Magicien</option>
                        <option value="Artificer">Artificier</option>
                    </select>

                    <label htmlFor="customClass">Laisse moi dont en inventer une!</label>
                    <input name="customClass" value={character.customClass} onChange={handleChange}/>

                    <label htmlFor="level">Niveau</label>
                    <input name="level" value={character.level} onChange={handleChange}/>

                    <label htmlFor="playername">Nom du joueur</label>
                    <input name="playername" value={character.playername} onChange={handleChange}/>

                    <label htmlFor="race">Race</label>
                    <select name="race" onChange={handleChange} value={character.race}>
                        <option value=""></option>
                        <option value="Human">Human - Humain</option>
                        <option value="Elf">Elf - Elfe</option>
                        <option value="Dwarf">Dwarf - Nain</option>
                        <option value="Halfling">Halfling - Halfelin</option>
                        <option value="Halfling">Eladrin</option>
                        <option value="Gnome">Gnome</option>
                        <option value="Dragonborn">Dragonborn - Draconien</option>
                        <option value="Tiefling">Tiefling - Tieffelin</option>
                        <option value="HalfElf">Half-Elf - Demi-Elfe</option>
                        <option value="HalfOrc">Half-Orc - Demi-Orc</option>
                        <option value="Orc">Orc</option>
                        <option value="Goblin">Goblin - Gobelin</option>
                        <option value="Hobgoblin">Hobgoblin - Hobgobelin</option>
                        <option value="Kobold">Kobold</option>
                        <option value="Lizardfolk">Lizardfolk - Homme-lézard</option>
                        <option value="Tabaxi">Tabaxi</option>
                        <option value="Aarakocra">Aarakocra</option>
                        <option value="Kenku">Kenku</option>
                        <option value="Goliath">Goliath</option>
                        <option value="Firbolg">Firbolg</option>
                        <option value="Triton">Triton</option>
                        <option value="Genasi">Genasi - Génasi</option>
                        <option value="Aasimar">Aasimar</option>
                        <option value="YuantiPureblood">Yuan-ti Pureblood - Yuan-ti Sang-Pur</option>
                        <option value="Bugbear">Bugbear</option>
                        <option value="Centaur">Centaur - Centaure</option>
                        <option value="Changeling">Changeling - Changeforme</option>
                        <option value="Kalashtar">Kalashtar</option>
                        <option value="Shifter">Shifter - Métamorphe</option>
                        <option value="Warforged">Warforged</option>
                        <option value="Minotaur">Minotaur - Minotaure</option>
                        <option value="Leonin">Leonin - Léonin</option>
                        <option value="Satyr">Satyr - Satyre</option>
                        <option value="Vedalken">Vedalken</option>
                        <option value="Loxodon">Loxodon</option>
                        <option value="SimicHybrid">Simic Hybrid - Hybride Simic</option>
                        <option value="Verdan">Verdan</option>
                        <option value="Grung">Grung</option>
                        <option value="Tortle">Tortle</option>
                        <option value="Gith">Gith</option>
                        <option value="Fairy">Fairy - Fée</option>
                        <option value="Harengon">Harengon</option>
                        <option value="Owlin">Owlin - Chouette</option>
                    </select>

                    <label htmlFor="customRace">Laisse moi dont en inventer une!</label>
                    <input name="customRace" value={character.customRace} onChange={handleChange}/>

                    <label htmlFor="subrace">Sous-Race (pour Will hahaha)</label>
                    <select name="subrace" onChange={handleChange} value={character.subrace}>
                        <option value=""></option>
                        <option value="warewolf">Loup-Garou</option>
                    </select>

                    <label htmlFor="customClass">Laisse moi dont en inventer une!</label>
                    <input name="customClass" value={character.customClass} onChange={handleChange}/>

                    <label htmlFor="alignment">Alignement</label>
                    <input name="alignment" value={character.alignment} onChange={handleChange}/>
                    <div className="background_story">
                        <h3>Histoire du personnage</h3>
                        <label htmlFor="background_story">
                            J'ai volontairement pas mis de "background". J'aime beaucoup mieux que vous inventiez une
                            histoire, courte ou longue pour votre personnage et je vais vous sortir des avantages en fonction de votre histoire.
                            Si vous choisissez un background de dnd, juste à écrire le nom du background et je vais chercher ses avantages qui y sont lié.
                        </label>
                        <textarea rows={15} name="background_story" value={character.background_story} onChange={handleChange}></textarea>
                    </div>
                </section>
            </header>
            <main>
                <section className="attributes">
                    <div className="scores">
                        <h2>Attributs</h2>
                        <div className="attribute">
                            <div className="score">
                                <h3>Force</h3>
                                <input name="Strengthscore" value={character.Strengthscore} onChange={handleChange}/>
                            </div>
                            <div className="modifier">
                                <span>Modificateur {Math.floor((parseInt(character.Strengthscore) - 10) / 2)}</span>
                            </div>
                        </div>
                        <div className="attribute">
                            <div className="score">
                                <h3>Dextérité</h3>
                                <input name="Dexterityscore" value={character.Dexterityscore} onChange={handleChange} />
                            </div>
                            <div className="modifier">
                                <span>Modificateur {Math.floor((parseInt(character.Dexterityscore) - 10) / 2)}</span>
                            </div>
                        </div>
                        <div className="attribute">
                            <div className="score">
                                <h3>Constitution</h3>
                                <input name="Constitutionscore" value={character.Constitutionscore} onChange={handleChange} />
                            </div>
                            <div className="modifier">
                                <span>Modificateur {Math.floor((parseInt(character.Constitutionscore) - 10) / 2)}</span>
                            </div>
                        </div>
                        <div className="attribute">
                            <div className="score">
                                <h3>Sagesse</h3>
                                <input name="Wisdomscore" value={character.Wisdomscore} onChange={handleChange} />
                            </div>
                            <div className="modifier">
                                <span>Modificateur {Math.floor((parseInt(character.Wisdomscore) - 10) / 2)}</span>
                            </div>
                        </div>
                        <div className="attribute">
                            <div className="score">
                                <h3>Intelligence</h3>
                                <input name="Intelligencescore" value={character.Intelligencescore} onChange={handleChange} />
                            </div>
                            <div className="modifier">
                                <span>Modificateur {Math.floor((parseInt(character.Intelligencescore) - 10) / 2)}</span>
                            </div>
                        </div>
                        <div className="attribute">
                            <div className="score">
                                <h3>Charisme</h3>
                                <input name="Charismascore" value={character.Charismascore} onChange={handleChange} />
                            </div>
                            <div className="modifier">
                                <span>Modificateur {Math.floor((parseInt(character.Charismascore) - 10) / 2)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="attr-applications">
                        <div className="proficiencybonus">
                            <h2>Bonus de Maîtrise</h2>
                            <h3>+{Math.floor((parseInt(character.level) - 1) / 4) + 2}</h3>
                        </div>
                        <div className="saves">
                            <h2>Jets de sauvegarde</h2>
                            <div>
                                <div className="attribut">Force</div>
                                <div className="modificateur"> {Math.floor((parseInt(character.Strengthscore) - 10) / 2)} </div>
                            </div>
                            <div>
                                <div className="attribut">Dextérité</div>
                                <div className="modificateur"> {Math.floor((parseInt(character.Dexterityscore) - 10) / 2)} </div>
                            </div>
                            <div>
                                <div className="attribut">Constitution</div>
                                <div className="modificateur"> {Math.floor((parseInt(character.Constitutionscore) - 10) / 2)} </div>
                            </div>
                            <div>
                                <div className="attribut">Sagesse</div>
                                <div className="modificateur"> {Math.floor((parseInt(character.Wisdomscore) - 10) / 2)} </div>
                            </div>
                            <div>
                                <div className="attribut">Intelligence</div>
                                <div className="modificateur"> {Math.floor((parseInt(character.Intelligencescore) - 10) / 2)} </div>
                            </div>
                            <div>
                                <div className="attribut">Charisme</div>
                                <div className="modificateur"> {Math.floor((parseInt(character.Charismascore) - 10) / 2)} </div>
                            </div>
                        </div>
                        <div className="skills">
                            <h2>Compétences</h2>
                            <div className="skill">
                                <input type="checkbox" name="selectAcrobatics" id="selectAcrobatics" checked={character.selectAcrobatics || false} onChange={handleChange} />
                                <label htmlFor="Acrobatics">Acrobaties (DEX)</label>
                                <div>
                                    {Math.floor((parseInt(character.Dexterityscore) - 10) / 2) + (character.selectAcrobatics ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectAnimalHandling" value={character.selectAnimalHandling} checked={character.selectAnimalHandling || false} onChange={handleChange} />
                                <label htmlFor="AnimalHandling">Animal Handling - Dressage (SAG)</label>
                                <div>
                                    {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectAnimalHandling ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectArcana" value={character.selectArcana} checked={character.selectArcana || false} onChange={handleChange} />
                                <label htmlFor="Arcana">Arcana - Arcanes (INT)</label>
                                <div>
                                    {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectArcana ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectAthletics" value={character.selectAthletics} checked={character.selectAthletics || false} onChange={handleChange} />
                                <label htmlFor="Athletics">Athletics - Athlétisme (FOR)</label>
                                <div>
                                    {Math.floor((parseInt(character.Strengthscore) - 10) / 2) + (character.selectAthletics ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectDeception" value={character.selectDeception} checked={character.selectDeception || false} onChange={handleChange} />
                                <label htmlFor="Deception">Deception - Tromperie (CHA)</label>
                                <div>
                                    {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectDeception ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectHistory" value={character.selectHistory} checked={character.selectHistory || false} onChange={handleChange} />
                                <label htmlFor="History">History - Histoire (INT)</label>
                                <div>
                                    {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectHistory ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectInsight" value={character.selectInsight} checked={character.selectInsight || false} onChange={handleChange} />
                                <label htmlFor="Insight">Insight - Perspicacité (SAG)</label>
                                <div>
                                    {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectInsight ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectIntimidation" value={character.selectIntimidation} checked={character.selectIntimidation || false} onChange={handleChange} />
                                <label htmlFor="Intimidation">Intimidation - Intimidation (CHA)</label>
                                <div>
                                    {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectIntimidation ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectInvestigation" value={character.selectInvestigation} checked={character.selectInvestigation || false} onChange={handleChange} />
                                <label htmlFor="Investigation">Investigation - Investigation (INT)</label>
                                <div>
                                    {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectInvestigation ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectMedicine" value={character.selectMedicine} checked={character.selectMedicine || false} onChange={handleChange} />
                                <label htmlFor="Medicine">Medicine (SAG)</label>
                                <div>
                                    {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectMedicine ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectNature" value={character.selectNature} checked={character.selectNature || false} onChange={handleChange} />
                                <label htmlFor="Nature">Nature (INT)</label>
                                <div>
                                    {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectNature ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectPerception" value={character.selectPerception} checked={character.selectPerception || false} onChange={handleChange} />
                                <label htmlFor="Perception">Perception (SAG)</label>
                                <div>
                                    {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectPerception ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectPerformance" value={character.selectPerformance} checked={character.selectPerformance || false} onChange={handleChange} />
                                <label htmlFor="Performance">Performance - Représentation (CHA)</label>
                                <div>
                                    {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectPerformance ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectPersuasion" value={character.selectPersuasion} checked={character.selectPersuasion || false} onChange={handleChange} />
                                <label htmlFor="Persuasion">Persuasion (CHA)</label>
                                <div>
                                    {Math.floor((parseInt(character.Charismascore) - 10) / 2) + (character.selectPersuasion ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectReligion" value={character.selectReligion} checked={character.selectReligion || false} onChange={handleChange} />
                                <label htmlFor="Religion">Religion (INT)</label>
                                <div>
                                    {Math.floor((parseInt(character.Intelligencescore) - 10) / 2) + (character.selectReligion ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectSleightofHand" value={character.selectSleightofHand} checked={character.selectSleightofHand || false} onChange={handleChange} />
                                <label htmlFor="Sleight of Hand">Sleight of Hand - Discrétion (DEX)</label>
                                <div>
                                    {Math.floor((parseInt(character.Dexterityscore) - 10) / 2) + (character.selectSleightofHand ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectStealth" value={character.selectStealth} checked={character.selectStealth || false} onChange={handleChange} />
                                <label htmlFor="Stealth">Stealth - Furtivité (DEX)</label>
                                <div>
                                    {Math.floor((parseInt(character.Dexterityscore) - 10) / 2) + (character.selectStealth ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                            <div className="skill">
                                <input type="checkbox" name="selectSurvival" value={character.selectSurvival} checked={character.selectSurvival || false} onChange={handleChange} />
                                <label htmlFor="Survival">Survival - Survie (SAG)</label>
                                <div>
                                    {Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectSurvival ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="passive-perception">
                    <h2>Perception Passive (SAG)</h2>
                    <div>
                        {10 + Math.floor((parseInt(character.Wisdomscore) - 10) / 2) + (character.selectPerception ? (Math.floor((parseInt(character.level) - 1) / 4) + 2) : 0)}
                    </div>
                </div>
                <div className="otherprofs">
                    <h2>Compétences ou Maîtrises Supplémentaires</h2>
                    <textarea rows={15} name="proficiencies" value={character.proficiencies} onChange={handleChange} ></textarea>
                </div>
                <div className="otherprofs">
                    <h2>Languages</h2>
                    <div>Autres languages que la langue commune</div>
                    <textarea rows={15} name="languages" value={character.languages} onChange={handleChange} ></textarea>
                </div>
                <section className="combat">
                    <h2>Combat stats</h2>
                    <div className="armorclass">
                        <h3>Classe d'armure</h3>
                        <input name="ac" type="text" value={character.ac} onChange={handleChange} />
                    </div>
                    <div className="initiative">
                        <h3>Modificateur d'initiative</h3>
                        <div>Dextérité, items, talents, dons ou équipements</div>
                        <input name="initiative" type="text" value={character.initiative} onChange={handleChange} />
                    </div>
                    <div className="speed">
                        <h3>Speed</h3>
                        <input name="speed" type="text" value={character.speed} onChange={handleChange} />
                    </div>
                    <div className="hp">
                        <h3>Les points de vie</h3>
                        <div className="max">
                            <label htmlFor="maxhp">Points de Vie Maximum</label>
                            <input name="maxhp" type="text" value={character.maxhp} onChange={handleChange} />
                        </div>
                        <div className="current">
                            <label htmlFor="currenthp">Points de vie actuellement</label>
                            <input name="currenthp" type="text" value={character.currenthp} onChange={handleChange} />
                        </div>
                        <div className="temporary">
                            <label htmlFor="temphp">Points de vie temporaires</label>
                            <input name="temphp" type="text" value={character.temphp} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="hitdice">
                        <h3>Dés de vie</h3>
                        <div className="total">
                            <label htmlFor="totalhd">Total</label>
                            <input name="totalhd" type="text" value={character.totalhd} onChange={handleChange} />
                        </div>
                        <div className="remaining">
                            <label htmlFor="remaininghd">Restant</label>
                            <input name="remaininghd" type="text" value={character.remaininghd} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="deathsaves">
                        <h3>Jets de Sauvegarde contre la Mort</h3>
                        <div className="deathsuccesses">
                            <label>Réussites</label>
                            <input name="deathsuccess1" type="checkbox"  onChange={handleChange}/>
                            <input name="deathsuccess2" type="checkbox"  onChange={handleChange}/>
                            <input name="deathsuccess3" type="checkbox"  onChange={handleChange}/>
                        </div>
                        <div className="deathfails">
                            <label>Échecs</label>
                            <input name="deathfail1" type="checkbox"  onChange={handleChange}/>
                            <input name="deathfail2" type="checkbox"  onChange={handleChange}/>
                            <input name="deathfail3" type="checkbox"  onChange={handleChange}/>
                        </div>
                    </div>
                </section>
                <section className="attacksandspellcasting">
                    <h2>Attaques & Sorts</h2>
                    <div>
                        <table>
                            <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Bonus d'attaque</th>
                                <th>Domage et type</th>
                            </tr>
                            </thead>
                            <tbody>
                            {character.attacks.map((atk, index) => (
                                <tr key={index}>
                                    <td><input name={`atk-name-${index}`} type="text" value={atk.name} onChange={handleChange} /></td>
                                    <td><input name={`atk-bonus-${index}`} type="text" value={atk.bonus} onChange={handleChange} /></td>
                                    <td><input name={`atk-dmg-${index}`} type="text" value={atk.dmg} onChange={handleChange} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </section>
                <section className="equipmentMoney">
                    <h2>Equipment</h2>
                    <div className="money">
                        <label htmlFor="cp">Cuivre</label><input name="cp" value={character.cp} onChange={handleChange} />
                        <label htmlFor="sp">Argent</label><input name="sp" value={character.sp} onChange={handleChange} />
                        <label htmlFor="gp">Or</label><input name="gp" value={character.gp} onChange={handleChange} />
                        <label htmlFor="pp">Platine</label><input name="pp" value={character.pp} onChange={handleChange} />
                    </div>
                    <div className="equipment">
                        <textarea rows={15} name="equipment" value={character.equipment} onChange={handleChange} ></textarea>
                        <label htmlFor="pp">Poids</label><input name="poids" value={character.poids} onChange={handleChange} />
                    </div>
                </section>
                <section className="flavor">
                    <h2>Informations le fun sur le personnage</h2>
                    <div className="personality">
                        <label htmlFor="personality">Personalité</label>
                        <textarea rows={15} name="personality" value={character.personality} onChange={handleChange} ></textarea>
                    </div>

                    <div className="ideals">
                        <label htmlFor="ideals">Idéaux</label>
                        <textarea rows={15} name="ideals" value={character.ideals} onChange={handleChange} ></textarea>
                    </div>

                    <div className="bonds">
                        <label htmlFor="bonds">Liens</label>
                        <textarea rows={15} name="bonds" value={character.bonds} onChange={handleChange} ></textarea>
                    </div>

                    <div className="flaws">
                        <label htmlFor="flaws">Défauts</label>
                        <textarea rows={15} name="flaws" value={character.flaws} onChange={handleChange} ></textarea>
                    </div>
                </section>
                <section className="features">
                    <h2>Caractéristiques et Traits</h2>
                    <textarea rows={15} name="features" value={character.features} onChange={handleChange} ></textarea>
                </section>
            </main>
            <button type="submit" >Enregistrer</button>
        </form>
           
    );
};

export default CharacterForm;
