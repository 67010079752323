import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCAABBbFuXgp1mor_Z2wWABGdb25KOz28U",
    authDomain: "dndtoolkit-410904.firebaseapp.com",
    projectId: "dndtoolkit-410904",
    storageBucket: "dndtoolkit-410904.appspot.com",
    messagingSenderId: "784136396440",
    appId: "1:784136396440:web:dbc238ec33df722018faed",
    measurementId: "G-CFDGCVVY8S"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);