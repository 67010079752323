import React, { useState } from 'react';
import Dice from "./Dice";

const DiceRoll = () => {
    const [numberOfDices, setNumberOfDices] = useState(1);
    const [numberOfFaces, setNumberOfFaces] = useState(6);
    const [results, setResults] = useState([]);

    const rollDice = () => {
        setResults([]);
        for (let i = 0; i < numberOfDices; i++) {
            const result = Math.floor(Math.random() * numberOfFaces) + 1;
            setResults(prevResults => [...prevResults, result]);
        }
    }

    return (
        <div className="diceContainer">
            <h1>Roll the dices!</h1>
            <div className="diceForm">
                <label>Nombre de dés</label>
                <input
                    type="number"
                    name="numberOfDices"
                    value={numberOfDices}
                    onChange={(e) => setNumberOfDices(Number(e.target.value))}
                    max={12}
                    min={1}
                />
            </div>
            <div className="diceForm">
                <label>Nombre de faces</label>
                <input
                    type="number"
                    name="numberOfFaces"
                    value={numberOfFaces}
                    onChange={(e) => setNumberOfFaces(Number(e.target.value))}
                    max={20}
                    min={3}
                />
            </div>
            <div className="dices">
                {results.map((result, index) => <Dice key={index} result={result}/>)}
            </div>
            <button onClick={rollDice}>Roll dice!</button>
        </div>
    );
};

export default DiceRoll;
