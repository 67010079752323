import React from 'react';

const CharacterList = ({ characters, handleCharacterSelect, character, handleNewCharacter }) => {
    return (
        <div className="character-selection">
            {characters.length > 0 && characters.map((char, index) => (
                <button key={index} onClick={() => handleCharacterSelect(char)} className={character.id === char.id? 'selected' : ''}>{char.charname}  ({char.playername})</button>
            ))}
        </div>
    );
};

export default CharacterList;