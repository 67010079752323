import Tile from './Tile';
import React, { useState } from 'react';

const importAll = (r) => {
    return r.keys().map((key) => ({
        path: r(key),
        name: key.split('/').pop().split('.')[0]
    }));
};


const monsterA = importAll(require.context('../images/monsters/A', true, /\.(png|jpe?g|svg)$/));
const monsterB = importAll(require.context('../images/monsters/B', true, /\.(png|jpe?g|svg)$/));
const monsterC = importAll(require.context('../images/monsters/C', true, /\.(png|jpe?g|svg)$/));
const monsterD = importAll(require.context('../images/monsters/D', true, /\.(png|jpe?g|svg)$/));
const monsterS = importAll(require.context('../images/monsters/S', true, /\.(png|jpe?g|svg)$/));

const categorizedImages = {
    'D': monsterD,
    'C': monsterC,
    'B': monsterB,
    'A': monsterA,
    'S': monsterS
};

const probabilities = {
    'Apprenti': { 'D': 75, 'C': 25 },
    'Explorateur': { 'D': 20, 'C': 75, 'B': 5 },
    'Aventurier Aventureux': { 'C': 70, 'B': 30 },
    'Expert Naissant': { 'C': 20, 'B': 75, 'A': 5 },
    'Expert': { 'B': 70, 'A': 30 },
    'HAHAHA Bonne chance': { 'A': 80, 'S': 20 },
    'Mazo': { 'S': 100 }
};

const DungeonCreator = () => {
    const [tiles, setTiles] = useState([]);
    const [numTiles, setNumTiles] = useState(0);
    const [maxMonsters, setMaxMonsters] = useState(0);
    const [maxTraps, setMaxTraps] = useState(0);
    const [maxTreasures, setMaxTreasures] = useState(0);

    console.log(numTiles, maxMonsters, maxTraps, maxTreasures)

    const handleTilesChange = (e) => {
        setNumTiles(e.target.value);
        const numTraps = document.querySelector('input[name="numberTraps"]').value;
        const numTreasures = document.querySelector('input[name="numberTreasures"]').value;
        const numMonsters = document.querySelector('input[name="numberMonsters"]').value;
        setMaxTraps((e.target.value - 1) * 9 - numMonsters - numTreasures);
        setMaxTreasures((e.target.value - 1) * 9 - numMonsters - numTraps);
        setMaxMonsters((e.target.value - 1) * 6 - numTreasures - numTraps);
    }
    const handleMonstersChange = (e) => {
        const numMonsters = e.target.value;
        const numTraps = document.querySelector('input[name="numberTraps"]').value;
        const numTreasures = document.querySelector('input[name="numberTreasures"]').value;
        setMaxTraps((numTiles - 1) * 9 - numMonsters - numTreasures);
        setMaxTreasures((numTiles - 1) * 9 - numMonsters - numTraps);
    }

    const handleTreasuresChange = (e) => {
        const numTreasures = e.target.value;
        const numMonsters = document.querySelector('input[name="numberMonsters"]').value;
        const numTraps = document.querySelector('input[name="numberTraps"]').value;
        setMaxMonsters((numTiles - 1) * 9 - numTreasures - numTraps);
        setMaxTraps((numTiles - 1) * 9 - numMonsters - numTreasures);
    }

    const handleTrapsChange = (e) => {
        const numTraps = e.target.value;
        const numMonsters = document.querySelector('input[name="numberMonsters"]').value;
        const numTreasures = document.querySelector('input[name="numberTreasures"]').value;
        setMaxMonsters((numTiles - 1) * 9 - numTreasures - numTraps);
        setMaxTreasures((numTiles - 1) * 9 - numMonsters - numTraps);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let dungeonMap = [];
        let tilesCoordinates = [];
        for (let i = 0; i < e.target.numberTiles.value; i++) {
            if(i === 0) {
                let xFirstTile = Math.floor(Math.random() * 4) + 1;
                tilesCoordinates.push({x: xFirstTile, y: 1});
                let xCoordinate = Math.floor((xFirstTile-1) * 520)
                let tile = {
                    forme: "square",
                    x: xFirstTile,
                    y: 1,
                    coordinates: {
                        x: xCoordinate,
                        y: 0
                    },
                    visited: true,
                    doors: { north: false, east: false, south: false, west: false },
                    monsters: [],
                    treasures: [],
                    traps: [],
                    space: 0
                }
                dungeonMap.push(tile);
            } else {
                let newTile = null;
                let directions = [
                    { x: 0, y: 1 },
                    { x: 1, y: 0 },
                    { x: 0, y: -1 },
                    { x: -1, y: 0 }
                ];
                while (!newTile) {
                    let randomIndex = Math.floor(Math.random() * tilesCoordinates.length);
                    let randomTile = tilesCoordinates[randomIndex];
                    let randomDirection = directions[Math.floor(Math.random() * directions.length)];
                    let newX = randomTile.x + randomDirection.x;
                    let newY = randomTile.y + randomDirection.y;
                    if (newY <= 0 || newX <= 0) continue;
                    let overlap = tilesCoordinates.some(tile => tile.x === newX && tile.y === newY);
                    if (!overlap) {
                        newTile = { x: newX, y: newY };
                        tilesCoordinates.push(newTile);
                        let xCoordinate = Math.floor((newX-1) * 520);
                        let yCoordinate = Math.floor((newY-1) * 520);
                        let doors = { north: false, east: false, south: false, west: false };
                        let tile = {
                            forme: "square",
                            x: newX,
                            y: newY,
                            coordinates: {
                                x: xCoordinate,
                                y: yCoordinate
                            },
                            visited: false,
                            doors: doors,
                            monsters: [],
                            treasures: [],
                            traps: [],
                            space: 0
                        };
                        for (let existingTile of dungeonMap) {
                            if (existingTile.x === newX && existingTile.y === newY - 1) {
                                existingTile.doors.south = true;
                                tile.doors.north = true;
                            } else if (existingTile.x === newX && existingTile.y === newY + 1) {
                                existingTile.doors.north = true;
                                tile.doors.south = true;
                            } else if (existingTile.x === newX - 1 && existingTile.y === newY) {
                                existingTile.doors.east = true;
                                tile.doors.west = true;
                            } else if (existingTile.x === newX + 1 && existingTile.y === newY) {
                                existingTile.doors.west = true;
                                tile.doors.east = true;
                            }
                        }
                        dungeonMap.push(tile);
                    }
                }
            }
        }

        if(e.target.numberMonsters.value > 0) {
            for (let i = 0; i < e.target.numberMonsters.value; i++) {
                const selectedProbabilities = probabilities[e.target.difficulty.value];
                let total = 0;
                const randomNumber = Math.random() * 100;
                let selectedCategory = null;

                for (const [category, probability] of Object.entries(selectedProbabilities)) {
                    total += probability;
                    if (randomNumber <= total) {
                        selectedCategory = category;
                        break;
                    }
                }

                let selectedMonster = null;

                if (selectedCategory) {
                    const categoryImages = categorizedImages[selectedCategory];
                    const randomIndex = Math.floor(Math.random() * categoryImages.length);
                    selectedMonster = categoryImages[randomIndex];
                }

                let randomIndex = Math.floor(Math.random() * dungeonMap.length);
                //Index cant be the first tile and tile should have less than 7 monsters
                while (randomIndex === 0 || dungeonMap[randomIndex].monsters.length >= 6) {
                    randomIndex = Math.floor(Math.random() * dungeonMap.length);
                }
                dungeonMap[randomIndex].monsters.push(selectedMonster);
                dungeonMap[randomIndex].space = dungeonMap[randomIndex].monsters.length;
            }
        }

        if(e.target.numberTreasures.value > 0) {
            for (let i = 0; i < e.target.numberTreasures.value; i++) {
                let randomIndex = Math.floor(Math.random() * dungeonMap.length);
                while (randomIndex === 0 || dungeonMap[randomIndex].space >= 9) {
                    randomIndex = Math.floor(Math.random() * dungeonMap.length);
                }
                dungeonMap[randomIndex].treasures.push("TREASURE");
                dungeonMap[randomIndex].space++;
            }
        }

        if(e.target.numberTraps.value > 0) {
            for (let i = 0; i < e.target.numberTraps.value; i++) {
                let randomIndex = Math.floor(Math.random() * dungeonMap.length);
                while (randomIndex === 0 || dungeonMap[randomIndex].space >= 9) {
                    randomIndex = Math.floor(Math.random() * dungeonMap.length);
                }
                dungeonMap[randomIndex].traps.push("TRAP");
                dungeonMap[randomIndex].space++;
            }
        }

        setTiles(dungeonMap);
    }
    console.log(tiles);
    const onExplore = (index, direction) => {
        setTiles(prevTiles => {
            const newTiles = [...prevTiles];
            const currentTile = newTiles[index];
            let adjacentTileIndex;
            switch (direction) {
                case "north":
                    adjacentTileIndex = newTiles.findIndex(tile => tile.x === currentTile.x && tile.y === currentTile.y - 1);
                    break;
                case "south":
                    adjacentTileIndex = newTiles.findIndex(tile => tile.x === currentTile.x && tile.y === currentTile.y + 1);
                    break;
                case "east":
                    adjacentTileIndex = newTiles.findIndex(tile => tile.x === currentTile.x + 1 && tile.y === currentTile.y);
                    break;
                case "west":
                    adjacentTileIndex = newTiles.findIndex(tile => tile.x === currentTile.x - 1 && tile.y === currentTile.y);
                    break;
                default:
                    break;
            }
            if (adjacentTileIndex !== -1) {
                newTiles[adjacentTileIndex].visited = true;
            }
            return newTiles;
        });
    }

    return (
        <div>
            <form onSubmit={onSubmit}>
                <input type="text" placeholder="Nom du donjon" name="name"/>
                <input type="number" placeholder="Nombre de pièces" name="numberTiles" min="1" onChange={handleTilesChange}/>
                <select name="difficulty">
                    <option value="Apprenti">Apprenti</option>
                    <option value="Explorateur">Explorateur</option>
                    <option value="Aventurier Aventureux">Aventurier Aventureux</option>
                    <option value="Expert Naissant">Expert Naissant</option>
                    <option value="Expert">Expert</option>
                    <option value="HAHAHA Bonne chance">HAHAHA Bonne chance</option>
                    <option value="Mazo">Mazo</option>
                </select>
                <input type="number" placeholder="Nombre de monstres" name="numberMonsters" max={maxMonsters} onChange={handleMonstersChange}/>
                <input type="number" placeholder="Nombre de trésors" name="numberTreasures" max={maxTreasures} onChange={handleTreasuresChange}/>
                <input type="number" placeholder="Nombre de pièges" name="numberTraps" max={maxTraps} onChange={handleTrapsChange}/>
                <button type="submit">Générer</button>
            </form>
            <div className="tilesContainer">
                {tiles.length > 0 && tiles.map((tile, index) => {
                    return <Tile key={index} index={index} tile={tile} onExplore={onExplore}/>
                })}
            </div>
        </div>
    );
};

export default DungeonCreator;
