import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import CharacterList from "./CharacterList";
import CharacterForm from "./CharacterForm";
import Characters from "./Characters";

const CharacterSheet = ({tab}) => {
    const [characters, setCharacters] = useState([]);
    const [character, setCharacter] = useState({});

    useEffect(() => {
        const myCollection = collection(db, 'players');
        getDocs(myCollection).then((querySnapshot) => {
            const characterData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const attacks = data.attacks || [];
                const updatedAttacks = [...attacks, { name: '', bonus: '', dmg: '' }];
                updatedAttacks.forEach((atk, index) => {
                    if (atk.name === "" && atk.bonus === "" && atk.dmg === "" && index !== 0 && index !== updatedAttacks.length) {
                        updatedAttacks.splice(index, 1);
                    }
                });
                characterData.push({ id: doc.id, ...data, attacks: updatedAttacks });
            });
            setCharacters(characterData);
            setCharacter(characterData[0])
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        });
    }, []);

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;

        // Gère les cas spécifiques des attaques
        if (name.startsWith("atk-")) {
            setCharacter(prevState => {
                const attacks = prevState.attacks;
                const parts = name.split("-");
                const field = parts[1]; // "name", "bonus", or "dmg"
                let atkIndex = parseInt(parts[2], 10);
                const maxIndex = attacks.length - 1;
                const nextIndex = attacks.length;

                // Cloner les attaques pour éviter de modifier l'état directement
                let updatedAttacks = [...attacks];

                // Mise à jour de l'attaque
                if (atkIndex === maxIndex) {
                    updatedAttacks[nextIndex] = { name: '', bonus: '', dmg: '' };
                }
                updatedAttacks[atkIndex][field] = value;
                //check the type of updatedAttacks[atkIndex] and if it's an array
                updatedAttacks.forEach((atk, index) => {
                    if (atk.name === "" && atk.bonus === "" && atk.dmg === "" && index !== 0 && index !== nextIndex && index !== maxIndex) {
                        updatedAttacks.splice(index, 1);
                    }
                });

                return { ...prevState, attacks: updatedAttacks };
            });
        } else {
            // Mise à jour standard pour les autres champs
            setCharacter(prevState => {
                // Vérifie si la valeur a changé pour éviter les mises à jour inutiles
                if ((type === 'checkbox' && prevState[name] === checked) || (type !== 'checkbox' && prevState[name] === value)) {
                    return prevState;
                }

                // Mise à jour de l'état avec la nouvelle valeur
                return {
                    ...prevState,
                    [name]: type === 'checkbox' ? checked : value,
                };
            });
        }
    };

    useEffect(() => {
        const updatedCharacters = characters.map(c => c.id === character.id ? character : c);
        setCharacters(updatedCharacters);
    }, [character]);


    const handleCharacterSelect = (selectedCharacter) => {
        setCharacter(selectedCharacter);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const characterData = { ...character };
            if(characterData.attacks && characterData.attacks.length > 0){
                characterData.attacks = characterData.attacks.filter(atk => atk.name !== '' && atk.bonus !== '' && atk.dmg !== '');
            }
            if (character.id && character.id !== 'new') {
                const characterRef = doc(db, 'players', character.id.toString());
                await updateDoc(characterRef, character);
                setCharacters(prevCharacters => prevCharacters.map(c => c.id === character.id ? character : c));
            } else {
                const newId = characters.length;
                const newCharacter = { ...character, id: newId };
                const newCharacterRef = doc(db, 'players', newId.toString());
                await setDoc(newCharacterRef, newCharacter);
                setCharacters(prevCharacters => [...prevCharacters, newCharacter]);
            }
            setCharacter({});
        } catch (error) {
            console.error("Error writing document: ", error);
        }
    };

    const handleNewCharacter = () => {
        const newCharacter = {
            id: 'new', // Identifiant unique pour le nouveau personnage
            charname: '', // Nom du personnage
            class: '', // Classe du personnage
            customClass: '', // Classe personnalisée
            subclass: '', // Sous-classe
            level: '', // Niveau
            playername: '', // Nom du joueur
            race: '', // Race du personnage
            customRace: '', // Race personnalisée
            alignment: '', // Alignement
            background_story: '', // Histoire du personnage
            Strengthscore: '', // Score de Force
            Dexterityscore: '', // Score de Dextérité
            Constitutionscore: '', // Score de Constitution
            Wisdomscore: '', // Score de Sagesse
            Intelligencescore: '', // Score d'Intelligence
            Charismascore: '', // Score de Charisme
            proficiencies: '', // Compétences ou maîtrises supplémentaires
            languages: '', // Langues parlées
            ac: '', // Classe d'armure
            initiative: '', // Modificateur d'initiative
            speed: '', // Vitesse de déplacement
            maxhp: '', // Points de vie maximum
            currenthp: '', // Points de vie actuels
            temphp: '', // Points de vie temporaires
            totalhd: '', // Total des dés de vie
            remaininghd: '', // Dés de vie restants
            deathsuccess1: false,
            deathsuccess2: false,
            deathsuccess3: false,
            deathfail1: false,
            deathfail2: false,
            deathfail3: false,
            attacks: [{ // Tableau des attaques
                name: '',
                bonus: '',
                dmg: ''
            }],
            cp: '', // Pièces de cuivre
            sp: '', // Pièces d'argent
            gp: '', // Pièces d'or
            pp: '', // Pièces de platine
            equipment: '', // Équipement
            poids: '', // Poids de l'équipement
            personality: '', // Personalité
            ideals: '', // Idéaux
            bonds: '', // Liens
            flaws: '', // Défauts
            features: '' // Caractéristiques et traits
        };
        setCharacters(prevCharacters => [newCharacter, ...prevCharacters]);
        setCharacter(newCharacter);
    };

    return (
        <div className='characterSheet'>
            {tab === 'characterSheet' &&
                <div className="sheets">
                    {character.length !== 0 && characters.length !== 0 && <Characters characters={characters}/>}
                </div>
            }
            {tab === 'characterSheetForm' &&
                <>
                    <div className="list">
                        {character.length !== 0 && characters.length !== 0 && <CharacterList characters={characters} handleCharacterSelect={handleCharacterSelect} character={character} handleNewCharacter={handleNewCharacter}/>}
                    </div>
                    <div className="sheetsForm">
                        {character.length !== 0 && characters.length !== 0 && <CharacterForm character={character} handleChange={handleChange} handleSubmit={handleSubmit}/>}
                    </div>
                </>
            }
        </div>
    )
}

export default CharacterSheet;
